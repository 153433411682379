import React, {useContext, useEffect} from "react";
import './gameCard.css';
import GameRating from "./GameRating";
import { AppContext } from "../App";
import { EffectCards } from "swiper/modules";
function GameCard({ game, sectionActive}) {
    const {library, setLibrary, bag, setBag, setGame} = useContext(AppContext);

    const handleAddToLibrary = game => {
        setLibrary([...library, game]);
    }

    const handleRemoveFromLibrary = game => {
        setLibrary(library.filter(item=>item._id !== game._id))
    }

    const handleAddToBag = game => {
        if (bag.includes(game)) return;
        setBag([...bag, game]);
    }
    const handleGameCicked = (game) => {
            setGame(game)
            sectionActive('gPage')
    }
    return (
        <div className="col-xl-3 col=lg-4 col-md-6">
            <div className="gameCard">
            <img src={game.img} alt={game.title} className="img-fluid"/>
            <a href="#" className={`like ${library.includes(game) ? 'active': undefined}`} onClick={
                library.includes(game)
                ? () => handleRemoveFromLibrary(game)
                : () => handleAddToLibrary(game)
            }>
                <i className="bi bi-heart-fill"></i>
            </a>
            <div className="gameFeature">
                <span className="gameType">{game.level}</span>
                <GameRating rating={game.rating}/>
            </div>
            <div className="gameTitle mt-4">{game.title}</div>
            <div className="gamePrice">
                {
                    game.discount != 0 && (
                        <>
                            <span className="discount"><i>{game.discount * 100}%</i></span>
                            <span className="prevPrice"><i>${game.price.toFixed(2)}</i></span>
                        </>
                    )
                }
                <span className="currentPrice">
                    {
                        game.price > 0 && (
                            <>${((1 - game.discount) * game.price).toFixed(2)}</>
                        ) || 
                        (
                            <>FREE</>
                        )
                    }
                </span>
            </div>
            <div className="buttons">
            <a href="#" className="orderBtn" onClick={()=>handleGameCicked(game)}>
                    Go to Page
                </a>
            </div>
            <a href="#" className="addBag" onClick={()=>handleAddToBag(game)}>
                <i className="bi bi-bag-plus-fill"></i>
            </a>
            </div>
        </div>
    )
}

export default GameCard;