import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import _debounce from 'lodash/debounce';
import { AppContext } from "../App";
function GameSlide({ game, active, ToggleVideo, rotation, sectionActive }) {
    const { setGame } = useContext(AppContext);
    const handleClick = (game) => {
        setGame(game);
        sectionActive('gPage')
    }
    const slideStyle = false
        ? { transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)` }
        : { transform: 'rotateX(0deg) rotateY(0deg)' };
    return (
        <SwiperSlide>
                       <div className="gameSlider" style={ slideStyle}>
                        <img src={game.img} alt="Game Image"></img>
                        <div className={`video ${active ? 'active':undefined}`}>
                            <iframe width="1280" height="720" src={game.trailer} title={game.title} allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="content">
                            <h2>{game.title}</h2>
                            <p>{game.description}</p>
                            <div className="buttons">
                                <a href="#" className="orderBtn" onClick={()=>handleClick(game)}>Go to page</a>
                                <a href="#" className={`playBtn ${active ? 'active': undefined}`} onClick={ToggleVideo}>
                                    <span className="pause">
                                        <i class="bi bi-pause-fill"></i>
                                    </span>
                                    <span className="play">
                                        <i class="bi bi-play-fill"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                       </div>
                    </SwiperSlide>
    )
}

export default GameSlide;