import { React, useState, useEffect } from "react";
import './gameRating.css'
function gen(stars = [], rating){
    if(stars.length > 0){
 
        return stars.map((star,index) => (
            
            star == true && (
                <i key={index} class="bi bi-star-fill active"></i>
            ) ||
            (
                <i key={index} class="bi bi-star-fill"></i>
            )
        ))
    }else{
        stars = [0,1,2,3,4]
        return stars.map((star,index) => (
            <i key={index} class="bi bi-star-fill"></i>
        ))
    }
}
function GameRating({ rating }) {
    const [stars, setStars] = useState([]);
    
    

    const generateStars = () => {
        let stars2 = [false,false,false,false,false];
        if( rating > 5 || rating < 1){
            return;
        }
        for(let i=0; i < rating; i++){
            stars2[i] = true;
        }

        return stars2;
    };
    useEffect(()=>{
        setStars(generateStars());
    },[]);
    return (
        <div className='gameRating'>
            {gen(stars, rating)}
        </div>
    )
}

export default GameRating;