import { React, useState} from "react";

import { Swiper, SwiperSlide } from 'swiper/react'


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';

import './gameSwiper.css'

import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules'
import GameSlide from "./GameSlide";

function GameSwiper({ games, sectionActive }){
    const [active, setActive] = useState(false);

    const [selected, setSelected] = useState(false);
    const handleToggleVideo = () => {
        setActive(!active);
    }

    const handleToggleSelected = () => {
        setActive(!selected);
    }

    const [rotation, setRotation] = useState({ x: 0, y: 0 });
    const handleMouseMove = (event) => {
        // Get the center of the element
        const rect = event.target.getBoundingClientRect();
        const center = {
            x: rect.left + rect.width / 2,
            y: rect.top + rect.height / 2,
        };

        // Calculate the angle between the cursor and the center of the element
        const angleX = -(event.clientY - center.y) / 50;
        const angleY = (event.clientX - center.x) / 50;

        // Update rotation state
        setRotation({ x: angleX, y: angleY });
    };

    const reloadRot = () => {
        setRotation({x:0,y:0})
    }

    return (
        <Swiper 
        effect={'coverflow'}
        grabCursor={true}
        navigation={true}
        loop={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
            rotate: 35,
            stretch: 200,
            depth: 250,
            modifier: 1,
            slideShadows: true,
        }}
       //autoplay={{
            //delay: 1000,
            //disableOnInteraction: false,
       //}}
        modules={[EffectCoverflow, Navigation, Autoplay]}
        className="gameSwiper">
            {
                games.map(game=>(
                    game.promo && (
                    <SwiperSlide key={game._id} onMouseMove={handleMouseMove} onMouseLeave={reloadRot}>
                    <GameSlide game={game} active={active} ToggleVideo={handleToggleVideo} rotation={rotation} selected={selected} ToggleSelected={handleToggleSelected} sectionActive={sectionActive}></GameSlide>
                    </SwiperSlide>
                    )
                ))
            }
        </Swiper>
    )
}

export default GameSwiper;