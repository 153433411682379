import { getDatabase, ref} from "firebase/database"
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzOuClfPL6hB6xQtYHU-4QUkk6KSy7bhY",
  authDomain: "game-store-ab282.firebaseapp.com",
  databaseURL: "https://game-store-ab282-default-rtdb.firebaseio.com",
  projectId: "game-store-ab282",
  storageBucket: "game-store-ab282.appspot.com",
  messagingSenderId: "839540933506",
  appId: "1:839540933506:web:5723426888cd37ecb3859b",
  measurementId: "G-60Z0T22D9T"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);


const Gamedata = ref(db, "Games");

const Filterdata = ref(db, "Filters")

export { Gamedata, db, Filterdata }