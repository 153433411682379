import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import React, { useState,useEffect } from 'react';
import './App.css';

import Main from './pages/Main'
export const AppContext = React.createContext();
function App() {
  const [library, setLibrary] = useState([])
  const [bag, setBag] = useState([])
  const [game, setGame] = useState([])

  const handleMGD = () => {
        const gdata = JSON.parse((localStorage.getItem("MGD") !== null ? localStorage.getItem("MGD") : "[]"));
        return gdata;
    }

  const handleMBG = () => {
    const gdata = JSON.parse((localStorage.getItem("MBG") !== null ? localStorage.getItem("MBG") : "[]"))
    return gdata;
  }

    useEffect(()=>{
        setLibrary(handleMGD());
        setBag(handleMBG());
    },[]) // You can adjust the interval time (in milliseconds) based on your needs

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // Simulate an asynchronous operation (e.g., fetching data from an API)
      const fetchData = async () => {
        // Simulate a delay
        await new Promise(resolve => setTimeout(resolve, 1000));

        setIsLoading(false);
      };
      fetchData();
  }, []);

    if (isLoading) {
      return <h1 className="loading" style={{fontSize:"100px", top:"calc(50% - 100px)"}}>XJET</h1>;
    }
  
  return (
    <>
    <AppContext.Provider value={{library, setLibrary, bag, setBag, game, setGame}}>
    <Main />
    </AppContext.Provider>
    </>

  );
}

export default App;
