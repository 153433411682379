import React, { useContext, useState, useEffect, useRef } from "react";
import './gPage.css'
import GameCard from "../components/GameCard";
import { AppContext } from "../App";
import DOMPurify from 'dompurify';
import { onValue } from "firebase/database";
import { Gamedata } from "../api/fire";

var ignore = false;
function GPage({ reference }) {
    const {library, setLibrary, bag, setBag, game, setGame} = useContext(AppContext);
    const [lgd, setlgd] = useState("");
    const [ignoreUpdate, setIgnoreUpdate] = useState(false);
    const [html, setHtml] = useState("");

    useEffect(() => {
        if(document.querySelector(".gPage").classList.contains("active")){
            const intervalId = setInterval(() => {
            return onValue(Gamedata, (snapshot) => {
                const data = snapshot.val();
                if (snapshot.exists()) {
                    if(game != data[game._id-1]){
                        setGame(data[game._id-1]);
                    }
                }
            })
        },100);

        return () => {
            clearInterval(intervalId);
        };
    }
    }, []);

    const handleAddToLibrary = game => {
        setLibrary([...library, game]);
    }

    const handleRemoveFromLibrary = game => {
        setLibrary(library.filter(item=>item._id !== game._id))
    }

    const handleAddToBag = game => {
        if (bag.includes(game)) return;
        setBag([...bag, game]);
    }

    

    const getGameData = (e) => { // Log the game object to the console
        if (game.html === lgd) {
          document.querySelector(".AboutInfo").innerHTML = game.html;
          setlgd(game.html)
          ignore = true;
        }
        if(lgd != game.html){
            ignore = false;
        }
      };

      useEffect(() => {
        var htmls = ""
        if(game.html != undefined){
        htmls = game.html
        htmls = htmls.replace("{game.img}", `"${game.img}"`)
        htmls = htmls.replace("{game.title}", (game.title))
        htmls = htmls.replace("{game.page}", (game.page))

        // Use DOMPurify to sanitize the HTML content
        const sanitizedHtml = DOMPurify.sanitize(htmls);
        setHtml(sanitizedHtml);
        }
      },[game]);

      if(game == undefined){
        return;
      }
    return (
        <section id="gPage" className="gPage" ref={reference}>
            <div className="gamePage">
            <img src={game.img} alt={game.title} style={{boxShadow:`0px 0px 10px ${game.gcolor}, 0px 0px 15px rgba(0,0,0,0)`}}/>
            <a href="#" className={`like ${library.includes(game) ? 'active': undefined}`} onClick={
                library.includes(game)
                ? () => handleRemoveFromLibrary(game)
                : () => handleAddToLibrary(game)
            }>
                <i className="bi bi-heart-fill"></i>
            </a>
            <div className="gameTitle"><center>{game.title}</center></div>
            <div className="gameDisc">{game.description}</div>
            <div className="buttons">
            <a href={game.page} className="orderBtn">
                    {
                        game.btn_message
                    }
                </a>
            </div>
            <div className="About">
                <div className="AboutTitle">
                    {
                        "About " + game.title
                    }
                </div>
                <div className="AboutInfo" dangerouslySetInnerHTML={{ __html: html }}>
                </div>
            </div>
            <a href="#" className="addBag" onClick={()=>handleAddToBag(game)}>
                <i className="bi bi-bag-plus-fill"></i>
            </a>
            </div>
        </section>
    );
}

export default GPage