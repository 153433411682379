import React from "react";
import './home.css'
import GameSwiper from "../components/GameSwiper";
import GameCard from "../components/GameCard";
function Home({ games, reference, sectionActive }) {
    return (
        <section id="home" className="home active" ref={reference}>
            <div className="container-fliud">
                <div className="row">
                    <GameSwiper games={games} sectionActive={sectionActive}></GameSwiper>
                </div>
                <div className="row mb-4 mt-4">
                    <div className="col-lg-6">
                        <h2 className="sectionTitle">Games on promotion</h2>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end align-items">
                        <a href="#" className="viewMore">
                        View More Games <i class="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    {
                        games.slice(0,4).map(game=>(
                            game.promo && (
                            <GameCard key={game._id} game={game} sectionActive={sectionActive}></GameCard>)
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default Home