import React, { useEffect, useState } from 'react'
import './sideMenu.css'
import navListData from '../data/navListData';
import NavListItem from './NavListItem';
function SideMenu({ active, sectionActive }) {
    const [navData, setNavData] = useState(navListData);

    const handleNavOnClick = (id, target)=>{
        const newNavData = navData.map(nav=>{
            nav.active = false;
            if(nav._id == id) nav.active=true;
            return nav;
        })
        setNavData(newNavData);
        sectionActive(target)
    }
    const handleUpdateNav = (id, target)=>{
        const newNavData = navData.map(nav=>{
            nav.active = false;
            if(nav._id == id) nav.active=true;
            return nav;
        })
        setNavData(newNavData);
        sectionActive(target)
    }
    useEffect(() => {
        const intervalId = setInterval(() => {
          const tick = localStorage.getItem("UNG");
          const id = localStorage.getItem("UNI");
          const target = localStorage.getItem("UNT");
    
          if (tick === "1") {
            handleUpdateNav(parseInt(id), target);
            localStorage.removeItem("UNG");
            localStorage.removeItem("UNI");
            localStorage.removeItem("UNT");
          }
        }, 1000); // You can adjust the interval time (in milliseconds) based on your needs
    
        return () => clearInterval(intervalId); // Clear the interval on component unmount
      }, []);
    return (
        <div class={`sideMenu ${active ? 'active':undefined}`}>
            <a href="" className="logo">
                <i className='bi bi-controller' title='XJet Games'></i>
                <span className='brand'>XJET</span>
                </a>
            <ul className="nav">
                {
                    navData.map(item => (
                        <NavListItem key={item._id} item={item} navOnClick={handleNavOnClick}/>
                    ))
                }
            </ul>
        </div>
    );
}

export default SideMenu;