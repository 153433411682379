import { React, useEffect, useState, useRef, useContext } from 'react';
import { AppContext } from '../App';
import './main.css'

import { onValue } from 'firebase/database'
import { Gamedata, db } from '../api/fire';

import SideMenu from '../components/SideMenu';
import Header from './Header';
import Home from './Home';
import Categories from './Categories';
import MyLibrary from './MyLibrary';
import Bag from './Bag';
import GPage from './GPage';
function Main() {
    const { library, bag, game } = useContext(AppContext);
    const [active, setActive] = useState(false);
    
    const [games, setGames] = useState([]);

    useEffect(() => {
        return onValue(Gamedata, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                setGames(data);
            }
        })
    }, []);
    useEffect(()=>{
        setActive(localStorage.getItem("SAS") === "1" ? true : false)
    },[])

    useEffect(() => {
        const intervalId = setInterval(() => {
            localStorage.setItem("SAS", active ? "1" : "0");
        }, 1000);
    
        return () => {
            clearInterval(intervalId);
        };
    }, [active]);
    

    const homeRef = useRef();
    const categoriesRef = useRef();
    const libraryRef = useRef();
    const bagRef = useRef();
    const GPageRef = useRef();

    const sections = [
        {
            name: 'home',
            ref: homeRef,
            active: true,
        },
        {
            name: 'categories',
            ref: categoriesRef,
            active: false,
        },
        {
            name: 'library',
            ref: libraryRef,
            active: false,
        },
        {
            name: 'bag',
            ref: bagRef,
            active: false,
        },
        {
            name: 'gPage',
            ref: GPageRef,
            active: false,
        }
    ]

    const handelToggleActive = () => {
        setActive(!active);
    }

    const handleSectionActive = target => {
        sections.map(section=>{
            section.ref.current.classList.remove('active');
            if(section.ref.current.id === target){
                section.ref.current.classList.add('active');
            }
            return section;
        });
    }

    return (
        <main>
            <SideMenu active={active} sectionActive={handleSectionActive}></SideMenu>
            <div className={`banner ${active ? 'active': undefined}`}>
                <Header toggleActive={handelToggleActive}/>
                <div className='container-fliud'>
                    {
                        games && games.length > 0 && (
                            <>
                            <Home games={games} reference={homeRef} sectionActive={handleSectionActive}/>
                            <Categories games={games} reference={categoriesRef} sectionActive={handleSectionActive}/>
                            <MyLibrary games={library} reference={libraryRef} sectionActive={handleSectionActive}/>
                            <Bag games={bag} reference={bagRef}/>
                            <GPage game={game} reference={GPageRef}/>
                            </>
                        )
                    }
                </div>
            </div>
        </main>
    );
}

export default Main;