import React, { useContext } from 'react'
import './header.css'
import { AppContext } from '../App';
function Header({ toggleActive }) {
    const { library, bag} = useContext(AppContext);
    const handleLibraryClicked = ()=>{
        localStorage.setItem("UNI","3")
        localStorage.setItem("UNT","library")
        localStorage.setItem("UNG","1")
    }
    const handleBagClicked = ()=>{
        localStorage.setItem("UNI","4")
        localStorage.setItem("UNT","bag")
        localStorage.setItem("UNG","1")
    }
    return (
        <header>
            <a href='#' className='menu' onClick={toggleActive}>
                <i class="bi bi-sliders"></i>
            </a>
            <div className='userItems'>
                <a href='#' className='icon' onClick={()=>handleLibraryClicked()}>
                    <i class="bi bi-heart-fill"></i>
                    <span className='like'>{library.length}</span>
                </a>
                <a href='#' className='icon' onClick={()=>handleBagClicked()}>
                    <i class="bi bi-bag-fill"></i>
                    <span className='bag'>{bag.length}</span>
                </a>
                <div className='avatar'>
                    <a href=''><img src="https://firebasestorage.googleapis.com/v0/b/scba-c045c.appspot.com/o/images%2FBasic_Button2.png?alt=media&token=51bddb59-b787-4f9d-ac6d-0c79dcb200ec"/></a>
                    <div className='user'>
                        <span>User Name</span>
                        <a href="">View Profile</a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;