import { React, useEffect } from "react";
import './myLibrary.css'
import GameCard from "../components/GameCard";
function MyLibrary({ games, reference, sectionActive}){

    const convertMGD = (table) => {
        // Use JSON.stringify to convert the array of objects to a string
        const jsonString = JSON.stringify(games);
    
        // Optionally, you can log the result to the console
        console.log(jsonString);
    
        // Return the resulting string
        return jsonString;
    };

            useEffect(()=>{
                const data = convertMGD();
                localStorage.setItem("MGD", data !== "" ? data : undefined);
            },[games])
    return (
        <section id="library" className="library" ref={reference}>
            <div className="container-fluid">
                <div className="row mb-3">
                    <h1>My Library</h1>
                </div>
                <div className="row">
                    {
                        games.length === 0 ? (
                            <h2>Your library is empty</h2>
                        ) : (
                            games.map(game=><GameCard key={game._id} game={game} sectionActive={sectionActive}/>)
                        )
                    }
                </div>
                </div>
        </section>
    )
}

export default MyLibrary;